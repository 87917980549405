<template>
  <div class="home">
    <v-parallax
        class="mb-4"
        height="370"
        src="../../assets/coliseo_grande.jpeg">
      <div class="home-header">
        <div class="text-center text-uppercase text-h3 white--text">Animación</div>
        <v-row class="mt-4 text-center justify-center">
          <v-col cols="12"
                 sm="12"
                 md="4"
                 lg="2">
            <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateFormatted"
                    label="Fecha"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="parseDate(dateFormatted)"
                    v-on="on"
                    solo
                    outlined
                    tile
                ></v-text-field>
              </template>
              <v-date-picker
                  first-day-of-week="1"
                  :min="new Date().toISOString()"
                  v-model="date"
                  @change="filterData"
                  no-title
                  range
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12"
                 sm="12"
                 md="4"
                 lg="2">
            <v-text-field
                v-model="people"
                @keypress="onlyNumber"
                label="Personas"
                append-icon="mdi-account-group"
                solo
                outlined
                tile
            ></v-text-field>
          </v-col>
          <v-col cols="12"
                 sm="12"
                 md="4"
                 lg="2">
            <v-select
                solo
                outlined
                tile
                hide-selected
                item-text="name"
                label="Tipo de espectáculo"
                item-value="id"
                :items="categories"
                v-model="category"
                @change="filterData"
            >
            </v-select>
          </v-col>
          <v-col v-if="dateFormatted || people || category"
                 cols="12"
                 sm="12"
                 md="4"
                 lg="1">

            <v-btn
                color="primary"
                @click="cleanFilters"
                fab>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </div>
    </v-parallax>
    <v-container fluid>
      <v-row v-if="amphiEvents.length > 0">
        <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="align-stretch"
            v-for="aphiEvent in amphiEvents"
            :key="aphiEvent.id">
          <v-card
              outlined
          >
            <!--                        <v-img-->
            <!--                            height="300"-->
            <!--                            :src="aphiEvent.image"-->
            <!--                        ></v-img>-->
            <v-img
                height="300"
                :src="aphiEvent.image"
                :lazy-src="aphiEvent.image"
            ></v-img>
            <v-container>
              <v-card-title class="primary--text">{{aphiEvent.name}}</v-card-title>
              <v-card-text>
                <div class="text-left my-2">{{aphiEvent.description | str_limit(120)}}</div>
                <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                >
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar
                          color="primary"
                          dark
                      >{{aphiEvent.name}}</v-toolbar>
                      <v-card-text>
                        <div class="pa-12">{{aphiEvent.description}}</div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn
                            text
                            @click="dialog.value = false"
                        >cerrar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                  <template v-slot:activator="{ on, attrs }">
                    <a v-bind="attrs"
                       v-on="on"
                       href="#">Saber Más</a>
                  </template>
                </v-dialog>
              </v-card-text>
              <v-card-actions>
                <v-btn
                    color="primary"
                    elevation="0"
                    tile
                    :to="`/about-event?id=${aphiEvent.id}`"
                ><v-icon
                    left
                    color="white"
                >
                  mdi-cart
                </v-icon> Comprar entradas</v-btn>
                <v-card-text class="primary--text"> <div class="text-right text-h4">{{aphiEvent.price}} € </div></v-card-text>

              </v-card-actions>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <div v-else class="my-6 text-center">


      </div>
    </v-container>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import {AmphiEvents, Category} from "@/services/api";

export default {
  name: 'Home',
  data(){
    return{
      date: null,
      menu1: false,
      menu2: false,
      categories: [],
      category: null,
      people: null,
      dateFormatted:null
    }
  },
  mounted() {
    AmphiEvents().getEvents()
        .then(response =>{
          this.$store.commit('setEvent', response.data.data)
          this.$store.commit('setLoading', false)
        })
    this.getCategory();
  },
  metaInfo() {
    return {
      title: `Anfiteatro La Marina`,
      meta: [
        {
          name: 'description',
          content: 'Saca tus entrdadas de forma facil y dinamica'
        },
      ]
    }
  },
  computed: mapState(['amphiEvents']),
  watch: {
    date () {
      let start_date = this.formatDate(this.date[0])
      let end_date = this.formatDate(this.date[1])
      if(end_date){
        this.dateFormatted = `${start_date} al ${end_date}`
      }else{
        this.dateFormatted = `${start_date}`
      }
      this.menu2 = false;

    },
  },
  methods: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    cleanFilters(){
      this.category= null
      this.people=null
      this.dateFormatted=null
      this.date=null
      this.filterData()
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    getCategory(){
      const authtype = this.$store.state.user ? this.$store.state.user.token_type: null;
      const token =  this.$store.state.user ? this.$store.state.user.access_token: null;
      Category(authtype, token).get().then(
          response => {
            this.categories =  response.data.data
            this.categories.push({
              value: null,
              name: 'Todos'
            })
          }
      )
    },
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    filterData(){
      this.$store.commit('setLoading', true)
      AmphiEvents().getEvents({
        start_date: this.date[0],
        end_date: this.date[1],
        category: this.category === 'Todos' ? null : this.category,
        capacity: this.people,
      })
          .then(response =>{
            this.$store.commit('setEvent', response.data.data)
            this.$store.commit('setLoading', false)
          })
    }
  },
}
</script>
